.main {
  margin: 10px;
}

.link-group {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 3px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}