.App {
  min-height: 100%;
  display: flex;
  flex-direction: row;
}

.segment {
  border: 1px solid #ccc;
  padding: 2px;
  margin: 5px 3px;
  cursor: pointer;
}

.segment.active {
  background-color: yellow;
}

/* .segment:hover {
  c
} */