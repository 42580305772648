
.video {
  max-width: 50%;
}

.video video {
  width: 100%;
}
.segment {
  border: 1px solid #ccc;
  padding: 2px;
  margin: 5px 3px;
  cursor: pointer;
}

.segment.active {
  background-color: yellow;
}

.transcript {
  overflow-y: scroll;
  max-height: 100vh;
}